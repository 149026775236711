import React from 'react';

export default function () {
	return (
		<div className="min-h-screen relative overflow-hidden flex items-center justify-center">
			<div className="flex flex-col z-10">
				<div className="flex items-center">
					<i style={{ color: '#63b3ed' }} className="fas fa-flask mr-1 mt"></i>
					<div className="text-xl font-semibold tracking-wide">prettymuchlabs</div>
				</div>
				<div>Building software at scale</div>
				<div className="mt-4">contact@prettymuchlabs.com</div>
			</div>
			<div className="w-screen h-screen absolute">
				<div style={{top: '-400px', right: '-300px' }} className="overflow-x-hidden absolute z-0">
					<img style={{ width: "1000px" }} src="/blob.svg" />
				</div>
				<div style={{top: '600px', left: '-120px' }} className="overflow-x-hidden absolute z-0">
					<img style={{ width: "300px" }} src="/circle.svg" />
				</div>
			</div>
		</div>
	);
}
