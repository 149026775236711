import React from 'react';
import Landing from './pages/landing';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Router path="/">
          <Landing />
        </Router>
      </Switch>
    </Router>
  );
}

export default App;
